import * as React from "react";
import styled from "@emotion/styled";
import PageWrapper from "../components/pageWrapper/PageWrapper";
import { ChevronBanner } from "@fitplan/lib/components/ChevronBanner";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";
import useMount from "react-use/lib/useMount";
import { loadGiftUp } from "../utils/giftup";
import { useStaticQuery, graphql, Link } from "gatsby";
import { SignUpRow } from "@fitplan/lib/components/UserAuth/layout";

interface Props {}

export interface GiftCardStrings {
    title: string;
    subtitle: string;
    buttonText: string;
    cta: string;
    howItWorks: string;
    step1Title: string;
    step1Text: string;
    step2Title: string;
    step2Text: string;
    step3Title: string;
    step3Text: string;
    heroImageSrc: string;
}

const GiftCardPage: React.FunctionComponent<Props> = ({ ...props }) => {
    const { giftCardBuy: strings } = useStaticQuery(graphql`
        query GiftCardPage {
            giftCardBuy {
                title
                subtitle
                buttonText
                cta
                howItWorks
                step1Title
                step1Text
                step2Title
                step2Text
                step3Title
                step3Text
                heroImageSrc
            }
        }
    `);
    useMount(() => {
        loadGiftUp(
            window,
            document,
            "script",
            "https://cdn.giftup.app/dist/gift-up.js",
            "giftup"
        );
    });
    const divRef = React.useRef<HTMLDivElement>();

    return (
        <PageWrapper {...props}>
            <ChevronBanner
                subtitle={strings.subtitle}
                src={`/images/giftcard-hero.jpg`}
                title={strings.title}
                button={strings.buttonText}
                onClick={() => {
                    if (
                        typeof window !== "undefined" &&
                        divRef &&
                        divRef.current
                    ) {
                        try {
                            window.scrollTo({
                                top: divRef.current.offsetTop - 100,
                            });
                        } catch (err) {
                            // For Safari
                            window.scrollTo(divRef.current.offsetTop, 0);
                        }
                    }
                }}
            />

            <CallToAction>
                Give an unlimited annual subscription to Fitplan!
            </CallToAction>

            <HowItWorks>{strings.howItWorks}</HowItWorks>
            <GrayBar />
            <StepsWrapper>
                <GiftSteps>
                    <StepTitle>{strings.step1Title}</StepTitle>
                    <StepInfo>{strings.step1Text}</StepInfo>
                </GiftSteps>
                <GiftSteps>
                    <StepTitle>{strings.step2Title}</StepTitle>
                    <StepInfo>{strings.step2Text}</StepInfo>
                </GiftSteps>
                <GiftSteps>
                    <StepTitle>{strings.step3Title}</StepTitle>
                    <StepInfo>{strings.step3Text}</StepInfo>
                </GiftSteps>
            </StepsWrapper>
            <div
                ref={divRef}
                className="gift-up-target"
                data-site-id="76ce3a35-4f54-4901-9f17-ff7cf23531ba"
                data-product-id="ba67ea62-142b-4913-e1d3-08d509dec75d"
                data-platform="Other"
            />
            <SignUpRow>
                <b>
                    If you have already have a gift card,
                    <Link to="/giftcard/redeem">REDEEM HERE</Link>.
                </b>
            </SignUpRow>
            <Polygon polygonColor="#111213" borderColor="#00ffb3" />
        </PageWrapper>
    );
};

const CallToAction = styled.div`
    font-family: Barlow;
    font-size: 21px;
    text-align: center;
    max-width: 60vw;
    margin: 40px 20vw;
`;

const HowItWorks = styled.h1`
    font-family: Barlow;
    font-size: 48px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    margin: 10px auto;
`;

const GrayBar = styled.div`
    background-image: linear-gradient(79deg, #00bd98, #28d95a);
    width: 80vw;
    height: 3px;
    margin: 0 10vw;
`;

const StepsWrapper = styled.div`
    text-align: center;
    max-width: 900px;
    margin: 0 auto 75px;
`;

const GiftSteps = styled.section`
    text-align: center;
    vertical-align: top;
    background: transparent;
    width: 300px;
    margin: 0 auto;
    display: inline-block;
`;

const StepTitle = styled.h2`
    font-family: Barlow;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    vertical-align: top;
    margin: 10px auto;
`;

const StepInfo = styled.p`
    font-family: Barlow;
    font-size: 18px;
    vertical-align: top;
    padding: 0 20px;
    margin-bottom: 10px;
`;

export default GiftCardPage;
